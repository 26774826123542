import Problem from "./Problem.vue"
import { ProblemProps } from "./Problem.props"

export default {
  title: "Search/Utils/Problem",
  component: Problem,
  parameters: {
    design: [
      {
        name: "Mobile",
        type: "figma",
        url: "https://www.figma.com/file/4bW0X5BVnq4gINLI1R8h9b/Design_SHARED_360?node-id=1357%3A166505&t=mMDx40MfIDyyMttw-4"
      },
      {
        name: "Desktop",
        type: "figma",
        url: "https://www.figma.com/file/UroefZie6mnN0FR8irrhED/Design_SHARED_1366?node-id=1493%3A184836&t=2jJXl4MuOr4QuIoH-4"
      }
    ]
  }
}

const Template = (args: ProblemProps) => ({
  components: { Problem },
  setup() {
    return {
      args
    }
  },
  template: '<Problem v-bind="args" />'
})

export const Default = Template.bind({}) as StorybookTemplateType<ProblemProps>
Default.args = {
  text: "Lorem ipsum dolor **sit amet consectetur**, adipisicing elit."
}
