<script setup lang="ts">
import { SearchbarFocusedProps } from "./SearchbarFocused.props"

defineProps<SearchbarFocusedProps>()

const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClose = () => {
  emit("onClose")
}
</script>

<template>
  <div class="search-searchbar_focused">
    <div
      class="
        md:grid-standard
        flex
        h-[60px]
        items-center
        gap-1
        bg-white
        shadow-01
        md:relative
        md:h-auto
        md:bg-tiffany-10
        md:py-9
      "
    >
      <UtilsButton theme="ghost-black" class="md:hidden" @click="handleClose">
        <template #icon>
          <UtilsIcon name="ArrowLeft.svg" class="h-6 w-6" />
        </template>
      </UtilsButton>
      <div class="flex-1 pr-2 md:col-span-8 md:col-start-3">
        <SearchSearchbar v-bind="searchSettings" />
      </div>
      <UtilsIcon
        name="Close.svg"
        class="
          ld:right-[228px]
          absolute
          right-[71px]
          top-5
          hidden
          h-6
          w-6
          cursor-pointer
          md:block
        "
        @click="handleClose"
      />
    </div>
    <div v-if="isUserLogged">
      <!-- desktop && user logged -->
      <div class="md:grid-standard md:conte hidden bg-white py-6 md:pb-8">
        <div class="col-span-4 space-y-10" :class="{ 'mb-14': textProblem }">
          <SearchUtilsSuggestions
            v-if="brands && !textProblem"
            v-bind="brands"
          />
          <SearchUtilsSuggestions
            v-if="symptoms && !textProblem"
            v-bind="symptoms"
          />
          <SearchUtilsInsights
            v-if="insights && !textProblem"
            v-bind="insights"
          />

          <SearchUtilsProblem v-if="textProblem" v-bind="textProblem" />
        </div>

        <SearchUtilsProducts
          class="col-span-8 col-start-5"
          v-if="products"
          v-bind="products"
        />
      </div>

      <!-- mobile && user logged -->
      <div class="padded bg-white md:hidden">
        <div class="pb-10" v-if="textProblem">
          <SearchUtilsProblem v-bind="textProblem" class="-mx-4 mb-4" />
          <SearchUtilsProducts v-if="products" v-bind="products" />
        </div>
        <div class="pt-4" v-else>
          <TheTabs v-bind="tabs" v-if="tabs" is-label-uppercase>
            <template #tabs="{ selectedTab }">
              <div class="py-4">
                <SearchUtilsProducts
                  v-if="products && selectedTab === 0"
                  v-bind="products"
                />
                <SearchUtilsSuggestions
                  v-if="brands && selectedTab === 1"
                  v-bind="brands"
                />
                <SearchUtilsSuggestions
                  v-if="symptoms && selectedTab === 2"
                  v-bind="symptoms"
                />
                <SearchUtilsInsights
                  v-if="insights && selectedTab === 3"
                  v-bind="insights"
                />
              </div>
            </template>
          </TheTabs>
        </div>
      </div>
    </div>
    <div v-else class="grid-standard bg-white py-4 md:pt-6">
      <!-- if user in NOT logged -->
      <SearchUtilsSuggestions
        v-if="popularResearches"
        class="col-span-full mb-6 md:col-span-8 md:col-start-3 md:mb-16"
        v-bind="popularResearches"
      />
      <BannerMenu
        v-if="menuBanner"
        class="col-span-full md:col-span-8 md:col-start-3 md:mb-16"
        v-bind="menuBanner"
      />
    </div>
  </div>
</template>
