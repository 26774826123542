import Menu from "./Menu.vue"
import { MenuProps } from "./Menu.props"
import { getImageData } from "~/utils/wrapperImage"

export default {
  title: "Banner/Menu",
  component: Menu,
  parameters: {
    design: [
      {
        name: "Mobile",
        type: "figma",
        url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=2222%3A17961"
      },
      {
        name: "Desktop",
        type: "figma",
        url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=2222%3A17962"
      }
    ]
  }
}

const Template = (args) => ({
  components: { Menu },
  setup() {
    return {
      args
    }
  },
  template: '<Menu v-bind="args" />'
})

export const OneBackgroundImage = Template.bind(
  {}
) as StorybookTemplateType<MenuProps>
OneBackgroundImage.args = {
  title: "Vuoi un’esperienza di ricerca personalizzata?",
  text: "Per un’esperienza di acquisto perfetta per te [Accedi](#) o [Registrati](#), riceverai **sconti e accessi in anteprima** alle nuove linee di prodotti",
  bgImageLarge: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/275/968/197",
    width: 968,
    height: 197
  })
}

export const ThreeBackgroundImages = Template.bind(
  {}
) as StorybookTemplateType<MenuProps>
ThreeBackgroundImages.args = {
  title: "Vuoi un’esperienza di ricerca personalizzata?",
  text: "Per un’esperienza di acquisto perfetta per te [Accedi](#) o [Registrati](#), riceverai **sconti e accessi in anteprima** alle nuove linee di prodotti",
  bgImageLarge: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/357/968/197",
    width: 968,
    height: 197
  }),
  bgImageMedium: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/302/808/197",
    width: 808,
    height: 197
  }),
  bgImageSmall: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/275/328/142",
    width: 328,
    height: 142
  })
}
