<script setup lang="ts">
import type { SuggestionsProps } from "./Suggestions.props"

defineProps<SuggestionsProps>()

const emit =
  defineEmits<{
    (e: "click", value: string): void
  }>()
</script>

<template>
  <div class="search-suggestions pt-4 md:pt-6">
    <p v-if="title" class="beaver-bold mb-2 text-green-main">
      {{ title }}
    </p>

    <div class="-mx-4 md:mx-0 md:grid md:grid-cols-1">
      <div
        class="
          inline-block
          max-w-full
          border-r
          last:border-0
          md:block
          md:border-0
        "
        v-for="item in suggestions"
      >
        <UtilsButton
          class="transform-gpu drop-shadow-01"
          :key="item.text"
          :to="item.path"
          :ellipsis="true"
          :fluid="true"
          theme="link-black"
          custom-classes="beaver-medium text-left inline-block px-4 md:px-0 py-2"
          v-bind="item"
          @click="emit('click', item.text!)"
        />
      </div>
    </div>
  </div>
</template>
