import { acceptHMRUpdate, defineStore } from "pinia"
import { MenuCategory } from "~/components/Menu/Sidebar.props"
import sidebarJSON from "~/static/data/sidebar.json"

export const useMenuStore = defineStore("menu", () => {
  const categories = ref(sidebarJSON ?? [])
  const openSideBar = ref(false)
  const selectedCategory = ref<MenuCategory[]>([])
  const lastActiveItemMenu = computed<MenuCategory>(
    () => selectedCategory.value[selectedCategory.value.length - 1]
  )
  const parentLastActiveItemMenu = computed<MenuCategory>(
    () => selectedCategory.value[selectedCategory.value.length - 2]
  )

  const activeSideBarOfferts = computed(
    () => selectedCategory?.value[1]?.featuredOffers ?? []
  )

  const selectMenuHandler = (event: any) => {
    const category = event.category
    if (selectedCategory.value.length === 0) {
      selectedCategory.value.push({
        category: "Tutte le categorie",
        isOpen: true,
        categories: []
      })
    }
    selectedCategory.value.push(category)
    category.isOpen = true
  }

  const goBack = () => {
    closeCategory(categories.value, lastActiveItemMenu.value.category)

    selectedCategory.value.pop()
    selectedCategory.value.length === 1 ? selectedCategory.value.pop() : null
    selectedCategory.value.length === 0 ? resetHamburgerMenu() : null
  }

  const closeCategory = (
    categories: MenuCategory[],
    findCategoryName: string
  ): void => {
    categories.map((category: MenuCategory) => {
      if (findCategoryName === category.category) {
        category.isOpen = false
        return category
      } else {
        if (category.categories.length > 0) {
          closeCategory(category.categories, findCategoryName)
        }
        return category
      }
    })
  }

  const setCategories = (newCategories: []) => {
    categories.value = newCategories
  }

  const resetSubMenu = (categories: any): any[] => {
    return [...categories].map((item) => {
      if (item?.categories && item?.categories.length > 0) {
        const newCat = resetSubMenu(item?.categories)
        return {
          ...item,
          categories: newCat,
          isOpen: false
        }
      } else {
        return {
          ...item,
          isOpen: false
        }
      }
    })
  }

  const resetHamburgerMenu = async () => {
    const newCat = await resetSubMenu(categories.value)
    if (newCat) categories.value = newCat
    selectedCategory.value = []
  }

  const openCloseSidebar = (value: boolean) => (openSideBar.value = value)
  const toggleSidebar = () => (openSideBar.value = !openSideBar.value)

  return {
    openSideBar,
    toggleSidebar,
    openCloseSidebar,
    categories,
    selectedCategory,
    lastActiveItemMenu,
    parentLastActiveItemMenu,
    setCategories,
    resetHamburgerMenu,
    goBack,
    selectMenuHandler,
    activeSideBarOfferts
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot))
