import Insights from "./Insights.vue"
import { InsightsProps, Insight } from "./Insights.props"

const ARTICLES: Insight = {
  insightsList: [
    {
      icon: "books",
      buttonData: { text: "Orecchio" }
    },
    {
      icon: "books",
      buttonData: { text: "Naso" }
    }
  ],
  moreInsights: {
    text: "Altri 3 articoli dell’Enciclopedia"
  }
}

const POSTS: Insight = {
  insightsList: [
    {
      icon: "post",
      buttonData: {
        text: "Hai dolore alle orecchie? Scopri le cause e i rimedi"
      }
    }
  ],
  moreInsights: {
    text: "Altri 2 post del TopMagazine"
  }
}

export default {
  title: "Search/Utils/Insights",
  component: Insights,
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=2975%3A45818&t=5NZqvbRmDwpsDb7W-4"
    }
  }
}

const Template = (args: InsightsProps) => ({
  components: { Insights },
  setup() {
    return {
      args
    }
  },
  template: '<Insights v-bind="args"/>'
})

export const Default = Template.bind({}) as StorybookTemplateType<InsightsProps>
Default.args = {
  title: "Approfondimenti",
  allInsights: [ARTICLES, POSTS]
}
