<script setup lang="ts">
import { MenuProps } from "./Menu.props"
import { sm, md, lg } from "~/utils/breakpoints"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

const props = defineProps<MenuProps>()

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
</script>

<template>
  <div class="banner-menu relative overflow-hidden rounded-lg lg:rounded-none">
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="
        wrapper-image-full-container-centered wrapper-image-fixed-144
        md:wrapper-image-fixed-196
        z-0
      "
      sizes="200px md:200px lg:250px xl:300px"
    />
    <div
      class="
        absolute
        inset-5
        top-4
        z-10
        flex flex-col
        gap-4
        text-center
        md:inset-12
        md:gap-6
        lg:inset-x-32
      "
    >
      <p v-if="title" class="beaver-bold md:dolphin-bold">{{ title }}</p>
      <UtilsMarkdown
        v-if="text"
        @click="navigateToLoginSignupPage"
        class="mouse-medium md:pig markdown__strong-custom-pig"
        :content="text"
      />
    </div>
  </div>
</template>
