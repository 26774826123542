import Products from "./Products.vue"
import { ProductsProps } from "./Products.props"
import { Default as ProductTile } from "../../ProductTile/Overall/Search.stories"

export default {
  title: "Search/Utils/Products",
  component: Products,
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=3097%3A54276&t=msryl7MLWMVmphqt-4"
    }
  }
}

const Template = (args: ProductsProps) => ({
  components: { Products },
  setup() {
    return {
      args
    }
  },
  template: '<Products v-bind="args"/>'
})

export const Default = Template.bind({}) as StorybookTemplateType<ProductsProps>
Default.args = {
  title: "Prodotti",
  cards: [
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args
  ],
  moreProducts: {
    text: "Visualizza tutti i risultati di ricerca (120)"
  }
}

export const WithError = Template.bind(
  {}
) as StorybookTemplateType<ProductsProps>
WithError.args = {
  title: "PRODOTTI più VENDUTI",
  cards: [
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args
  ],
  moreProducts: {
    text: "Visualizza tutti i prodotti più venduti (60)"
  },
  errorDescription:
    "Lorem ipsum dolor sit amet [consectetur](#), **adipisicing** elit."
}
export const Suggestion = Template.bind(
  {}
) as StorybookTemplateType<ProductsProps>
Suggestion.args = {
  title: "PRODOTTI più VENDUTI",
  cards: [
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args,
    ProductTile.args
  ],
  moreProducts: {
    text: "Visualizza tutti i prodotti più venduti (60)"
  },
  suggestion: "[tachipirina](#)"
}
