import Searchbar from "./Searchbar.vue"
import { SearchbarProps } from "./Searchbar.props"

export default {
  title: "Search/Searchbar",
  component: Searchbar,
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=4069%3A86732&t=094N6r6C1vPuR49G-4"
    }
  },
  decorators: [
    () => ({
      template: '<div style="width: 60%;"><story/></div>'
    })
  ]
}

const Template = (args: SearchbarProps) => ({
  components: { Searchbar },
  setup() {
    const inputText = ref(args.modelValue)
    return {
      args,
      inputText
    }
  },
  template: '<Searchbar v-bind="args" v-model="inputText"/>'
})

export const Default = Template.bind(
  {}
) as StorybookTemplateType<SearchbarProps>
Default.args = {
  name: "search",
  label: "Search label",
  placeholder: "Cerca per prodotto, marca o sintomo",
  isPlaceholderAnimated: true,
  modelValue: "",
  disabled: false,
  showTextIcon: true
}

export const WithValue = Template.bind(
  {}
) as StorybookTemplateType<SearchbarProps>
WithValue.args = {
  ...Default.args,
  modelValue: "Searched value"
}

export const Disabled = Template.bind(
  {}
) as StorybookTemplateType<SearchbarProps>
Disabled.args = {
  ...Default.args,
  disabled: true
}

export const DisabledWithValue = Template.bind(
  {}
) as StorybookTemplateType<SearchbarProps>
DisabledWithValue.args = {
  ...WithValue.args,
  disabled: true
}
