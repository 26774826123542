import Suggestions from "./Suggestions.vue"
import { SuggestionsProps } from "./Suggestions.props"

export default {
  title: "Search/Utils/Suggestions",
  component: Suggestions,
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=2975%3A45818&t=5NZqvbRmDwpsDb7W-4"
    }
  }
}

const Template = (args: SuggestionsProps) => ({
  components: { Suggestions },
  setup() {
    return {
      args
    }
  },
  template: '<Suggestions v-bind="args"/>'
})

export const Brands = Template.bind(
  {}
) as StorybookTemplateType<SuggestionsProps>
Brands.args = {
  title: "marche",
  suggestions: [
    {
      text: "Marca 1"
    },
    {
      text: "Marca 2 media"
    },
    {
      text: "Marca 3 lunghissima"
    },
    {
      text: "Marca 4"
    }
  ]
}

export const Symptoms = Template.bind(
  {}
) as StorybookTemplateType<SuggestionsProps>
Symptoms.args = {
  title: "sintomi",
  suggestions: [
    {
      text: "Sintomo 1"
    },
    {
      text: "Sintomo 2 medio"
    },
    {
      text: "Sintomo 3 lunghissimo"
    },
    {
      text: "Sintomo 4"
    }
  ]
}

export const PopularResearches = Template.bind(
  {}
) as StorybookTemplateType<SuggestionsProps>
PopularResearches.args = {
  title: "ricerche popolari",
  suggestions: [
    {
      text: "Ricerca 1"
    },
    {
      text: "Ricerca 2 media"
    },
    {
      text: "Ricerca 3"
    },
    {
      text: "Ricerca 4 lunghissima"
    },
    {
      text: "Ricerca 5"
    },
    {
      text: "Ricerca 6 lunghissima"
    },
    {
      text: "Ricerca 7"
    }
  ]
}
