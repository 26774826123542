import Search from "./Search.vue"
import { SearchProps } from "./Search.props"
import { getImageData } from "~/utils/wrapperImage"
import { Discount, Promo } from "~/components/Badge/Product.stories"

export default {
  title: "ProductTile/Overall/Search",
  component: Search,
  argTypes: {},
  parameters: {
    design: [
      {
        name: "Mobile",
        type: "figma",
        url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=1797%3A11503&t=ZQZ0GNrlj8EkADwn-4"
      },
      {
        name: "Desktop",
        type: "figma",
        url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=2141%3A25094&t=ZQZ0GNrlj8EkADwn-4"
      }
    ]
  }
}

const Template = (args: SearchProps) => ({
  components: { Search },
  setup() {
    return {
      args
    }
  },
  template: '<Search v-bind="args" />'
})

export const Default = Template.bind({}) as StorybookTemplateType<SearchProps>
Default.args = {
  productImage: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/1025/70/72",
    width: 70,
    height: 72
  }),
  title: "Carnidyn Plus Integratore stanchezza fisica e mentale 20 bustine",
  isPromo: true,
  oldPrice: 14.5,
  isSearchResults: false, //true only when productTileSearch is instanced in SearchUtilsProducts
  fullWidth: false,
  currentPrice: 18.3,
  discount: 20,
  path: "#",
  promotions: [Discount.args, Promo.args]
}

export const alreadyInCart = Template.bind(
  {}
) as StorybookTemplateType<SearchProps>
alreadyInCart.args = {
  ...Default.args,
  alreadyInCart: true
}

export const notAvailable = Template.bind(
  {}
) as StorybookTemplateType<SearchProps>
notAvailable.args = {
  ...Default.args,
  inStock: false,
  availableIn: 5
}

export const LongVersion = Template.bind(
  {}
) as StorybookTemplateType<SearchProps>
LongVersion.args = {
  ...Default.args,
  longVersion: true
}

export const FullWidth = Template.bind({}) as StorybookTemplateType<SearchProps>
FullWidth.args = {
  ...Default.args,
  fullWidth: true
}

export const SearchResults = Template.bind(
  {}
) as StorybookTemplateType<SearchProps>
SearchResults.args = {
  ...Default.args,
  isSearchResults: true
}

export const noButtonVersion = Template.bind(
  {}
) as StorybookTemplateType<SearchProps>
noButtonVersion.args = {
  ...Default.args,
  noButton: true
}
