import SearchbarFocused from "./SearchbarFocused.vue"
import { SearchbarFocusedProps } from "./SearchbarFocused.props"
import { Default as Searchbar } from "./Searchbar.stories"
import {
  Brands,
  Symptoms,
  PopularResearches
} from "./Utils/Suggestions.stories"
import { Default as Insight } from "./Utils/Insights.stories"
import {
  Default as Producs,
  WithError as ProducsWithError,
  Suggestion as ProductSuggestion
} from "./Utils/Products.stories"
import { OneBackgroundImage } from "../Banner/Menu.stories"
import { Default as Problem } from "./Utils/Problem.stories"

export default {
  title: "Search/SearchbarFocused",
  component: SearchbarFocused,
  parameters: {
    layout: "fullscreen",
    design: [
      {
        name: "Mobile",
        type: "figma",
        url: "https://www.figma.com/file/4bW0X5BVnq4gINLI1R8h9b/Design_SHARED_360?node-id=1357%3A166565&t=mMDx40MfIDyyMttw-4"
      },
      {
        name: "Desktop",
        type: "figma",
        url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=1920%3A9186&t=msryl7MLWMVmphqt-4"
      }
    ]
  }
}

const Template = (args: SearchbarFocusedProps) => ({
  components: { SearchbarFocused },
  setup() {
    return {
      args
    }
  },
  template: '<SearchbarFocused v-bind="args" />'
})

export const DefaultNotLogged = Template.bind(
  {}
) as StorybookTemplateType<SearchbarFocusedProps>
DefaultNotLogged.args = {
  isUserLogged: false,
  searchSettings: Searchbar.args,
  popularResearches: PopularResearches.args,
  menuBanner: OneBackgroundImage.args
}

export const DefaultLogged = Template.bind(
  {}
) as StorybookTemplateType<SearchbarFocusedProps>
DefaultLogged.args = {
  ...DefaultNotLogged.args,
  isUserLogged: true,
  tabs: {
    tabsList: [
      { text: "Prodotti" },
      { text: "Marche" },
      { text: "Sintomi" },
      { text: "Approfondimenti" }
    ]
  },
  brands: Brands.args,
  symptoms: Symptoms.args,
  insights: Insight.args,
  products: Producs.args
}

export const DefaultLoggedWithProblem = Template.bind(
  {}
) as StorybookTemplateType<SearchbarFocusedProps>
DefaultLoggedWithProblem.args = {
  ...DefaultLogged.args,
  textProblem: Problem.args,
  products: ProducsWithError.args
}
export const DefaultLoggedWithSuggestion = Template.bind(
  {}
) as StorybookTemplateType<SearchbarFocusedProps>
DefaultLoggedWithSuggestion.args = {
  ...DefaultLogged.args,
  products: ProductSuggestion.args
}
