import Product from "./Product.vue"
import { ProductProps } from "./Product.props"

export default {
  title: "Badge/Product",
  component: Product,
  argTypes: {
    theme: {
      options: ["SCONTO", "PROMO", "FUORI TUTTO", "BLACK FRIDAY", "NOVITÀ"],
      control: { type: "select" }
    }
  },
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/cmtQx1ALJCk4x4WbLoqzRO/TopFarmacia_DS?node-id=1786%3A9079"
    }
  }
}

const Template = (args: ProductProps) => ({
  components: { Product },
  setup() {
    return {
      args
    }
  },
  template: '<Product v-bind="args" />'
})

export const Discount = Template.bind({}) as StorybookTemplateType<ProductProps>
Discount.args = {
  theme: "SCONTO",
  text: "-33%"
}

export const Promo = Template.bind({}) as StorybookTemplateType<ProductProps>
Promo.args = {
  theme: "PROMO"
}

export const AllOut = Template.bind({}) as StorybookTemplateType<ProductProps>
AllOut.args = {
  theme: "FUORI TUTTO"
}

export const LastPiece = Template.bind(
  {}
) as StorybookTemplateType<ProductProps>
LastPiece.args = {
  theme: "ULTIMI PEZZI"
}

export const New = Template.bind({}) as StorybookTemplateType<ProductProps>
New.args = {
  theme: "NOVITÀ"
}

export const BlackFriday = Template.bind(
  {}
) as StorybookTemplateType<ProductProps>
BlackFriday.args = {
  theme: "BLACK FRIDAY"
}
